import React from 'react';
import { Link } from 'gatsby';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { App } from '@layouts';
import useABTesting from 'hooks/useABTesting';

const Settings = () => {
  const { t } = useABTesting();

  return (
    <App>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4>Settings</h4>
        <Link to="/">
          <Typography variant="body2">Go back</Typography>
        </Link>
      </Box>
    </App>
  );
};

export default Settings;
